import * as React from "react"
import Icon from "../Icon/Icon"
import "./Lecture.css"

const Lecture = ({ index, lecture }) => {
  const free = lecture.tier === "free"

  const ltitle = lecture.lecturetitle ? lecture.lecturetitle : lecture.title

  const content = (
    <>
      <strong>{index}. </strong>
      <span>
        <span className="lecture-title">
          {lecture.medium === "audio" && (
            <Icon name="audio" tooltip="Audio Content" />
          )}
          {lecture.medium === "video" && (
            <Icon name="video" tooltip="Video Content" />
          )}
          {ltitle}
        </span>
        {!free && (
          <span className="lecture-badge-wrapper">
            <small className={`lecture-badge lecture-badge--${lecture.tier}`}>
              {lecture.tier}
            </small>
          </span>
        )}
      </span>
    </>
  )

  return (
    <>
      <li className="lecture">
        <a
          href={lecture.permalink ?? "#"}
          className="lecture-link lecture-link--free"
        >
          {content}
        </a>
      </li>
    </>
  )
}

export default Lecture
