import * as React from "react"
import "./Button.css"

const Button = ({ primary, children }) => (
  <button className={`button button--${primary ? "primary" : "secondary"}`}>
    {children}
  </button>
)

export default Button
