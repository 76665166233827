import React, { useRef, useEffect } from "react"
import "./Form.css"

const Form = ({ children }) => {
  const newsletterFormDivRef = useRef()

  useEffect(() => {
    const script = document.createElement("script")
    script.async = true
    script.src =
      "https://eocampaign1.com/form/ae59da9e-6a39-11ec-96e5-06b4694bee2a.js"
    script.setAttribute("data-form", "ae59da9e-6a39-11ec-96e5-06b4694bee2a")

    const newsletterFormDiv = newsletterFormDivRef.current
    newsletterFormDiv.appendChild(script)
  }, [])

  return (
    <>
      <div className="newsletter-wrapper" ref={newsletterFormDivRef}></div>
      {/* TODO reactivate this later <form className="form">{children}</form> */}
    </>
  )
}

export default Form
