import * as React from "react"
import "./Banner.css"

const Banner = ({ primary, title, content, image, thumbnail }) => (
  <section className={`banner banner--${primary ? "primary" : "secondary"}`}>
    <div className="content banner-content">
      {image && <div className="banner-image">{image}</div>}
      <div className="banner-text">
        <div className="banner-headline">
          {thumbnail && <div className="banner-thumbnail">{thumbnail}</div>}
          <h3>{title}</h3>
        </div>
        {content}
      </div>
    </div>
  </section>
)

export default Banner
