import * as React from "react"
import Ellipse from "./Ellipsis"
import "./Hero.css"
import ButtonStyledLinkSamePage from "../ButtonStyledLinkSamePage/ButtonStyledLinkSamePage"

const Hero = ({ content }) => (
  <>
    <Ellipse />
    <section className="hero content">
      <h1>Make code reviews your superpower.</h1>
      <p>
        Code Reviews are either a team’s superpower or an expensive bottleneck.
        Learn how to boost your productivity and the value you get from code
        reviews.
      </p>
      <ButtonStyledLinkSamePage
        href="/workshops"
        primary
      >
        Book A Code Review Workshop
      </ButtonStyledLinkSamePage>
    </section>
  </>
)

export default Hero
