import * as React from "react"
import "./ButtonStyledLinkSamePage.css"

const ButtonStyledLinkSamePage = ({ primary, small, children, href }) => (
  <a
    className={`button button--${primary ? "primary" : "outline"} ${
      small ? "button--small" : ""
    }`}
    href={href}
  >
    {children}
  </a>
)

export default ButtonStyledLinkSamePage
