import * as React from "react"

const Ellipsis = () => (
  <svg
    className="hero-ellipsis"
    width="1440"
    height="864"
    viewBox="-300 -20 2040 764"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1961 -243.002C1583 495.998 1200.5 616.593 641.001 726.996C162.82 821.353 -461 760 -758 244.998C-1258.84 -623.473 -901.5 -452.999 598.502 -453C1981 -453 2146.15 -604.968 1961 -243.002Z"
      fill="hsl(270, 66%, 47%)"
    />
    <path
      d="M1961 -243.002C1583 495.998 1200.5 616.593 641.001 726.996C162.82 821.353 -461 760 -758 244.998C-1258.84 -623.473 -901.5 -452.999 598.502 -453C1981 -453 2146.15 -604.968 1961 -243.002Z"
      fill="url(#gradient)"
    />
    <defs>
      <linearGradient
        id="gradient"
        x1="555.907"
        y1="-320.073"
        x2="701.5"
        y2="781.498"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="hsl(274, 56%, 72%)" stopOpacity="0.3" />
        <stop offset="1" stopColor="hsl(274, 56%, 72%)" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default Ellipsis
