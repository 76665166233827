import { graphql } from "gatsby"
import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"
import Button from "../components/Button/Button"
import ButtonStyledLink from "../components/ButtonStyledLink/ButtonStyledLink"
import ButtonStyledLinkSamePage from "../components/ButtonStyledLinkSamePage/ButtonStyledLinkSamePage"
import Banner from "../components/Banner/Banner"
import Form from "../components/Form/Form"
import Hero from "../components/Hero/Hero"
import Layout from "../components/layout"
import LectureCard from "../components/LectureCard/LectureCard"
import Seo from "../components/seo"
import VideoBanner from "../components/VideoBanner/VideoBanner"
import VideoCard from "../components/VideoCard/VideoCard"


import "bootstrap/dist/css/bootstrap.min.css"


const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  // Organize all lectures by topic
  const lecturesByTopic = {}
  data.allMarkdownRemark.nodes
    .filter(content => content.frontmatter.type === "lecture")
    .forEach(lecture => {
      lecture.frontmatter.topics.forEach(([topic, sortindex]) => {
        lecturesByTopic[topic] = [
          ...(lecturesByTopic[topic] ?? []),
          { ...lecture.frontmatter, sortindex },
        ]
      })
    })

  // Add all related lectures to each topic and sort them by sortindex
  const topics = data.allMarkdownRemark.nodes
    .filter(content => content.frontmatter.type === "topic")
    .map(topic => {
      const lectures = lecturesByTopic[
        topic.fields.slug.replace(/\//g, "")
      ].sort((a, b) => a.sortindex - b.sortindex)

      return { ...topic.frontmatter, lectures }
    })

  const topicCard = (topic, key) =>
    topic.medium === "video" ? (
      <VideoCard content={topic} key={key} />
    ) : (
      <LectureCard content={topic} key={key} />
    )

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Make code reviews your superpower" />

      <Hero />
      <main>
        <h2 className="content">Improve your code reviews</h2>
        {topics
          .slice(0, 2)
          .map((card, index) => topicCard(card, `card-${index}`))}
        <Banner
          title="Unleash your full code review potential!"
          content={
            <>
              <p>
                Increase productivity and happiness with a live code review
                workshop by code review expert Dr. Greiler.
              </p>
              <p>
                In this remote workshop, your team identifies code review
                bottlenecks, learns code review best practices, and learns how
                to overcome their main code review roadblocks. Workshops can be
                booked individually, or for a complete team.
              </p>
              <ButtonStyledLinkSamePage
                href="/workshops"
                primary
              >
                Book A Company Workshop
              </ButtonStyledLinkSamePage>
              <ButtonStyledLink
                href="https://checkout.tito.io/doctor-mckayla/"
                secondary
              >
                Public Workshops
              </ButtonStyledLink>
            </>
          }
          image={
            <StaticImage
              alt="Dr. Michaela Greiler"
              formats={["auto", "webp", "avif"]}
              layout="fixed"
              quality={95}
              src="../images/michaela_greiler.jpeg"
              width={360}
              height={360}
            />
          }
          thumbnail={
            <StaticImage
              alt="Dr. Michaela Greiler"
              formats={["auto", "webp", "avif"]}
              layout="fixed"
              quality={95}
              src="../images/michaela_greiler.jpeg"
              width={70}
              height={70}
            />
          }
        />
        
        {topics
          .slice(2, 4)
          .map((card, index) => topicCard(card, `card-${index + 2}`))}

        <Banner
          primary
          title="Get the Code Review e-Book"
          content={
            <>
              <p>
                Boost the value of code reviews with insights from this 20-pages
                e-Book.
              </p>
              <p>
                The e-Book includes code review best practices, a code review
                checklist, and some additional goodies on code reviews I haven't
                covered before.
              </p>
              <br />
              <p>All you need is your email. Sign-up today!</p>
              <Form>
                <input type="text" placeholder="First name" />
                <input type="email" placeholder="Email address" />
                <Button>I want in</Button>
              </Form>
            </>
          }
        />

        {topics
          .slice(4, 6)
          .map((card, index) => topicCard(card, `card-${index + 4}`))}
        <VideoBanner
          title="Boost your code review productivity"
          description="Developer productivity"
          videoId="5Kwf0OA57E8"
        />
       
        
        {topics
          .slice(7)
          .map((card, index) => topicCard(card, `card-${index + 7}`))}
    <VideoBanner
          title="Improve your code review feedback"
          description="10 tips for respectful and constructive code review feedback."
          videoId="NNXk_WJzyMI"
        />
    <VideoBanner
          title="Finding Security Vulnerabilities through Code Review"
          description="The OWASP way"
          videoId="kpf3UkMc5Y4"
        />
      </main>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          status: { eq: "publish" }
          type: { in: ["topic", "lecture"] }
        }
      }
      sort: { fields: [frontmatter___sortindex], order: ASC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          lecturetitle
          description
          type
          color
          illustration
          tier
          medium
          topics
          videoId
          permalink
        }
      }
    }
  }
`
