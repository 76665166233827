import * as React from "react"
import Lecture from "../LectureCard/Lecture"
import Video from "../Video/Video"
import "./VideoCard.css"

const Card = ({ content }) => (
  <div
    className="content"
    style={{
      "--hsl-light": `var(--hsl-rainbow--${content.color}-light)`,
      "--hsl": `var(--hsl-rainbow--${content.color})`,
      "--hsl-dark": `var(--hsl-rainbow--${content.color}-dark)`,
    }}
  >
    <section className="video-card">
      <Video {...content} />
      <div className="video-card-lectures">
        <h4>Lectures</h4>
        <ul>
          {content.lectures.map((lecture, index) => (
            <Lecture
              lecture={lecture}
              index={index + 1}
              key={`${index}-${lecture.name}`}
            />
          ))}
        </ul>
      </div>
    </section>
  </div>
)

export default Card
